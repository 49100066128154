jQuery(document).ready(function($){
	$("#login").wrapInner("<div />").children().addClass("visc-login-inner");

	$(".visc-login-inner").append("<div id='visceral-brand'><a id='brand-img' href='https://thisisvisceral.com' title='Go to Visceral website'></a><span>Crafted by <a href='https://thisisvisceral.com' title='Go to Visceral website'>Visceral</a></span></div>");

	$("#loginform label").contents().first().wrap("<span />");
	$("#loginform label[for='user_pass']").contents().first().wrap("<span />");

	if ( $("form label[for='user_login']").length ) {
	  $("form label[for='user_login']").contents().first().wrap("<span />");
	}

	$("#user_login").attr("placeholder", "Username or Email Address");
	$("#user_pass").attr("placeholder", "Password");

});